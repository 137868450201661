<style scoped>
.map-tag{
  position: fixed;
  top: 15px;
  left: 45%;
  z-index: 1;
  text-align: center;
}
.map-title{
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 999;
  text-align: right;
  font-size: 16px;
}
.map-time{
  position: fixed;
  top: 40px;
  left: 45%;
  z-index: 999;
  width: 260px;
  text-align: center;
}
.map-container {width:100%; }
.circle {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: inline-flex;
}
</style>

<template>
    <div>
        <div class="map-tag">
          <div class="circle" style="background-color:#979797"></div>
          <span class="text-14 m-l-5 m-r-5">未开始</span>
          <div class="circle" style="background-color:#0096ff"></div>
          <span class="text-14 m-l-5 m-r-5">正在作业</span>
          <div class="circle" style="background-color:#40ec5d"></div>
          <span class="text-14 m-l-5 m-r-5">已完成</span>
          <div class="circle" style="background-color:#ed3434"></div>
          <span class="text-14 m-l-5">预警</span>
        </div>

        <div class="map-title">
          <span class=" m-l-50 text-14">打卡：</span><a class="text-orange" @click="modalCheckin=true">{{checkinList.length}}</a> | {{currentTime}} | {{weatherBean.weather}} | {{weatherBean.temperature}} ℃ | {{weatherBean.windDirection}}风 {{weatherBean.windPower}}/秒
        </div>

        <div class="map-time" v-if="cuurentCountdown!==''">
          <span class="text-30">倒计时 <b class="m-l-5 text-orange">{{cuurentCountdown}}</b></span>
        </div>

        <div id="dispathMap" class="map-container"></div>

        <Modal v-model="modalCheckin" width="800" title="工人打卡记录" footer-hide>
            <Table  stripe size="small" :columns="checkinColumns" :data="checkinList" ></Table>
        </Modal>
    </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { ParseDateTime } from '@/utils/dateFormat'
import { getStorage } from '@/utils/storage'
import { getCheckinList, getStationInfoList } from '@/api/msp/taskwork'

export default {
  data () {
    return {
      currentTime: '',
      cuurentCountdown: '',
      weatherBean: {},
      checkinList: [], // 打卡记录
      modalCheckin: false,
      checkinColumns: [
        { title: '姓名', width: 90, key: 'createUserName' },
        { title: '打卡线路', key: 'assetName' },
        { title: '打卡站点', width: 100, key: 'domainName' },
        { title: '时间', key: 'createTime' }
      ],

      amapObject: null, // 地图对象
      grayFlicker: require('@/assets/images/flicker_gray2.gif'),
      greenFlicker: require('@/assets/images/flicker_green2.gif'),
      blueFlicker: require('@/assets/images/flicker_blue2.gif'),
      redFlicker: require('@/assets/images/flicker_red2.gif'),

      blueMarker: require('@/assets/images/metro_bluemarker.png'),
      greenMarker: require('@/assets/images/metro_greenmarker.png'),
      redMarker: require('@/assets/images/metro_redmarker.png'),
      yellowMarker: require('@/assets/images/metro_yellow.png'),
      purpleMarker: require('@/assets/images/metro_purple.png'),

      needleMarker: require('@/assets/images/needle.png'),

      needleList: [], // 区域站点信息
      workgroupList: [], // 工作分组

      stationMarkers: [], // 站点闪烁标记组
      needleMarkers: [], // 标记分组
      circleMarkers: [], // 工作分组
      lineMarkers: [], // 线路分组

      publisherCityCode: this.$store.getters.token.userInfo.publishers[0].cityCode,
      mapCityCenter: [
        { cityCode: '350200', cityName: '厦门市', center: [118.103886046, 24.4892306125] },
        { cityCode: '370200', cityName: '青岛市', center: [120.383414, 36.075069] },
        { cityCode: '110100', cityName: '北京市', center: [116.395645038, 39.9299857781] },
        { cityCode: '330200', cityName: '宁波市', center: [121.579005973, 29.8852589659] },
        { cityCode: '510100', cityName: '成都市', center: [104.067923463, 30.6799428454] }
      ]
    }
  },
  mounted () {
    document.getElementById('dispathMap').style.height = document.body.clientHeight + 'px'
    this.loadCurrentTime()
    this.initMap()
  },
  methods: {
    initPageData () {
      const postData = { actionDate: this.actionDate }

      getCheckinList(postData).then(res => {
        this.checkinList = res
      })
      // 用于标注工作区域， 和工作路劲
      getStationInfoList(postData).then(res => {
        this.needleList = res
        this.workgroupList = []

        res.forEach(element => {
          if (!this.workgroupList.find(x => x.workgroupId === element.workgroupId)) {
            this.workgroupList.push({
              workgroupId: element.workgroupId,
              workgroupName: element.workgroupName
            })
          }
        })
      })
    },
    loadCurrentTime () {
      this.currentTime = ParseDateTime(new Date())
      setTimeout(() => {
        this.loadCurrentTime()
      }, 1000)
    },
    loadCountdown () {
      setTimeout(() => {
        if (!this.lastestTime) { return }

        var lefttime = this.lastestTime - new Date().getTime() // 距离结束时间的毫秒数

        var leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)) // 计算天数
        var lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24) // 计算小时数
        var leftm = Math.floor(lefttime / (1000 * 60) % 60) // 计算分钟数
        var lefts = Math.floor(lefttime / 1000 % 60) // 计算秒数

        const showDays = leftd > 0 ? (leftd + '天') : ''
        if (lefth <= 0 && leftm <= 0 && lefts <= 0) {
          this.cuurentCountdown = ''
        } else {
          this.cuurentCountdown = showDays + lefth + ':' + leftm + ':' + lefts // 返回倒计时的字符串
          this.loadCountdown()
        }
      }, 1000)
    },
    initMap () {
      // 获取主题皮肤，设置地图底色
      let mapStyleUrl = ''
      if (this.themeIsDark) {
        mapStyleUrl = 'amap://styles/22479512d3a2c18a06bc6ec613e012c7'
      }

      lazyAMapApiLoaderInstance.load().then(() => {
        // your code ...
        const center = this.mapCityCenter.find(x => x.cityCode === this.publisherCityCode).center
        this.amapObject = new AMap.Map('dispathMap', {
          resizeEnable: true,
          center: new AMap.LngLat(center[0], center[1]),
          mapStyle: mapStyleUrl,
          zoom: 12,
          zooms: [10, 16]
        })

        this.getWeather()
      })
    },
    getWeather () { // 获取地图天气信息
      const that = this

      AMap.plugin('AMap.Weather', function () {
        // 创建天气查询实例
        var weather = new AMap.Weather()

        // 执行实时天气信息查询
        const cityName = that.mapCityCenter.find(x => x.cityCode === that.publisherCityCode).cityName
        weather.getLive(cityName, function (err, data) {
          if (err === null) {
            that.weatherBean = data
          }
        })
      })
    },

    addOtherMarker () {
      this.clearPageMarker()

      if (this.mapTagType === 'tag') { // 添加图针
        this.loadPageMarker()
        let marker
        this.needleMarkers = []
        this.needleList.filter(x => x.workgroupId === this.mapTagWorkgroupId).forEach(element => {
          marker = new AMap.Marker({
            position: new AMap.LngLat(element.gdLongitude, element.gdLatitude),
            offset: new AMap.Pixel(-8, -70),
            icon: this.formatNeedleMarkerIcon() // 添加 Icon 实例
          })
          this.needleMarkers.push(marker)
        })

        this.setNeedleAnimation()
        this.amapObject.add(this.needleMarkers)
        this.amapObject.setFitView()
      } else if (this.mapTagType === 'workgroup') { // 添加圆形标注
        this.loadPageMarker()

        this.circleMarkers = []
        let circleByWorkgroup = null
        this.workgroupList.forEach(element => {
          circleByWorkgroup = this.computedCircle(this.needleList.filter(x => x.workgroupId === element.workgroupId))
          if (circleByWorkgroup) {
            this.circleMarkers.push(circleByWorkgroup)
          }
        })

        this.amapObject.add(this.circleMarkers)
        this.amapObject.setFitView()
      } else if (this.mapTagType === 'line') { // 添加线路图
        this.lineMarkers = []
        let marker
        const markerColor = [
          this.blueMarker, this.greenMarker, this.redMarker, this.yellowMarker, this.purpleMarker
        ]
        let lineByWorkgroup = null
        let wNum = 0
        this.workgroupList.forEach(element => {
          lineByWorkgroup = this.needleList.filter(x => x.workgroupId === element.workgroupId).sort((a, b) => { return b.index < a.index })
          // 循环获取当前分组内上刊的点位集合
          let startNum = 1
          lineByWorkgroup.forEach(work => {
            marker = new AMap.Marker({
              position: new AMap.LngLat(work.gdLongitude, work.gdLatitude),
              offset: new AMap.Pixel(-8, -35),
              icon: this.formatLineMarkerIcon(markerColor[wNum], startNum), // 添加 Icon 实例
              label: {
                offset: new AMap.Pixel(0, -2), // 设置文本标注偏移量
                content: startNum, // 设置文本标注内容
                direction: 'center' // 设置文本标注方位
              }
            })
            this.lineMarkers.push(marker)
            startNum++
          })
          wNum = (wNum >= 4) ? 0 : wNum + 1
        })

        this.amapObject.add(this.lineMarkers)
        this.amapObject.setFitView()
      }
    },
    computedCircle (stations) {
      let distanceWidth = 300 // 默认椭圆高半径
      let distanceHeight = 300 // 默认椭圆宽半径
      let circleCenter = null

      if (stations.length === 1) {
        distanceWidth = 300
        distanceHeight = 300
        circleCenter = new AMap.LngLat(stations[0].gdLongitude, stations[0].gdLatitude)
      } else if (stations.length === 2) {
        distanceWidth = 500
        distanceHeight = 500
        circleCenter = new AMap.LngLat((stations[0].gdLongitude + stations[1].gdLongitude) / 2, (stations[0].gdLatitude + stations[1].gdLatitude) / 2)
      } else {
        // 求取所有点位集合的最大矩形点位， 然后计算出矩形的长宽
        const lngs = stations.map(x => parseFloat(x.gdLongitude))
        const lats = stations.map(x => parseFloat(x.gdLatitude))

        const maxLng = Math.max.apply(null, lngs)
        const minLng = Math.min.apply(null, lngs)
        const maxLat = Math.max.apply(null, lats)
        const minLat = Math.min.apply(null, lats)
        circleCenter = new AMap.LngLat((maxLng + minLng) / 2, (maxLat + minLat) / 2)

        const startWidthPoint = new AMap.LngLat(maxLng, maxLat)
        const startHeightPoint = new AMap.LngLat(minLng, minLat)

        distanceWidth = Math.round(startWidthPoint.distance(circleCenter)) + 100
        distanceHeight = Math.round(startHeightPoint.distance(circleCenter)) + 100
      }

      return new AMap.Ellipse({
        center: circleCenter, // 圆心位置
        radius: [distanceWidth, distanceHeight], // 圆半径
        fillOpacity: 0.3,
        fillColor: '#1791fc',
        strokeOpacity: 0,
        strokeColor: '#1791fc', // 描边颜色
        strokeWeight: 0 // 描边宽度
      })
    },
    setNeedleAnimation () {
      let startPointY = -70
      const endPointY = -20

      this.needleMarkers.forEach(element => {
        const intervalID = setInterval(function () {
          if (startPointY++ < endPointY) {
            element.setOffset(new AMap.Pixel(-8, startPointY))
          } else {
          // 取消无限循环
            clearInterval(intervalID)
          }
        }, 20) // 每次请求为50 ms
      })
    },

    initStationMarkerData () {
      const showStationList = []

      let stationProgressBean
      this.domainProgress.forEach(element => {
        stationProgressBean = showStationList.find(x => x.domainName === element.domainName)
        if (stationProgressBean) {
          // 变更状态
          if (stationProgressBean.status < element.status) {
            stationProgressBean.status = element.status
          }
          // 增加换乘站ID
          if (!stationProgressBean.domainId.toString().split(',').includes(element.domainId.toString())) {
            stationProgressBean.domainId += ',' + element.domainId
            stationProgressBean.assetId += ',' + element.assetId
            stationProgressBean.assetName += ',' + element.assetName
          }
        } else { // 添加站点信息
          showStationList.push({
            domainId: element.domainId,
            domainName: element.domainName,
            assetId: element.assetId,
            assetName: element.assetName,
            status: element.status,
            lng: element.gdLongitude,
            lat: element.gdLatitude
          })
        }
      })
      this.addMapMarker(showStationList)
    },
    addMapMarker (stationList) { // 添加闪烁气泡点
      this.stationMarkers = []
      let marker
      const _that = this
      // 添加闪烁点
      stationList.forEach(element => {
        marker = new AMap.Marker({
          position: new AMap.LngLat(element.lng, element.lat),
          offset: new AMap.Pixel(-25, -25),
          icon: this.formatStationMarkerIcon(element.status), // 添加 Icon 实例
          title: element.domainName
        }).on('click', function () { _that.handleClickStation(element) })

        this.stationMarkers.push(marker)
      })

      this.amapObject.add(this.stationMarkers)
    },
    formatStationMarkerIcon (status) { // 闪烁图标
      // "0 未开始 1 执行中 2 已完成 3 警告"
      let imgUrl = ''
      if (status === 0) {
        imgUrl = this.grayFlicker
      } else if (status === 1) {
        imgUrl = this.blueFlicker
      } else if (status === 2) {
        imgUrl = this.greenFlicker
      } else {
        imgUrl = this.redFlicker
      }

      return new AMap.Icon({
        size: new AMap.Size(50, 50), // 图标尺寸
        image: imgUrl, // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(50, 50) // 根据所设置的大小拉伸或压缩图片
      })
    },
    formatNeedleMarkerIcon () { // 针尖定位
      return new AMap.Icon({
        size: new AMap.Size(13, 25), // 图标尺寸
        image: this.needleMarker, // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(13, 31) // 根据所设置的大小拉伸或压缩图片
      })
    },
    formatLineMarkerIcon (color, num) {
      return new AMap.Icon({
        size: new AMap.Size(24, 30), // 图标尺寸
        image: color, // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(24, 30) // 根据所设置的大小拉伸或压缩图片
      })
    },
    handleClickStation (stationBean) {
      this.$store.commit('set_dispatch_stationSvgBean', stationBean)
      this.$store.commit('set_dispatch_show_stationSvg', true)
    },
    loadPageMarker () { // 验证地图是否加载完毕
      if (this.amapObject) {
        this.initStationMarkerData()
      } else {
        setTimeout(() => {
          this.loadPageMarker()
        }, 1000)
      }
    },
    clearPageMarker () {
      if (this.amapObject) {
        // 清空全部标注
        this.amapObject.remove(this.stationMarkers)
        this.amapObject.remove(this.needleMarkers)
        this.amapObject.remove(this.circleMarkers)
        this.amapObject.remove(this.lineMarkers)
      } else {
        setTimeout(() => {
          this.clearPageMarker()
        }, 1000)
      }
    }
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    },
    domainProgress () {
      return this.$store.state.dispatch.domainProgress
    },
    mapReload () {
      return this.$store.state.dispatch.mapReload
    },
    lastestTime () {
      // 解决页面关闭后， 无限循环无法获取store的BUG
      if (!this.$store.state.dispatch || this.$store.state.dispatch.lastestTime === '0000-00-00 00:00:00') {
        return undefined
      } else {
        return new Date(this.$store.state.dispatch.lastestTime).getTime()
      }
    },
    mapDate () { // 用户页面底部按钮，执行显示工作区域和路劲
      return this.$store.state.dispatch.mapDate
    },
    mapTagType () {
      return this.$store.state.dispatch.mapTagType
    },
    mapTagWorkgroupId () {
      return this.$store.state.dispatch.mapTagWorkgroupId
    },
    showStationSvg () {
      return this.$store.state.dispatch.showStationSvg
    },
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  watch: {
    mapReload (val) {
      this.clearPageMarker()
      this.loadPageMarker()
    },
    lastestTime (val) {
      this.loadCountdown()
    },
    mapDate (val) {
      this.addOtherMarker()
    },
    actionDate (val) {
      this.initPageData()

      this.cuurentCountdown = ''
      this.loadCountdown()
    }
  }

}
</script>
